@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
html {
  scroll-behavior: smooth;
  color: #525252;
}
.whyussection {
  background-color: #9cc3c5;
}
.contact-section {
  background-color: #f6f6f6;
}
.footer-bg {
  background-color: #272727;
  color: #fff;
}

.font-DosisRegular {
  font-family: "DosisRegular";
}
.font-DosisBold {
  font-family: "DosisBold";
}
.font-DosisMedium {
  font-family: "DosisMedium";
}
.font-InterExtraBold {
  font-family: "InterExtraBold";
}
.font-InterBold {
  font-family: "InterBold";
}
.font-InterRegular {
  font-family: "InterRegular";
}
.font-InterMedium {
  font-family: "InterMedium";
}
@layer base {
  @import "font.css";
  /* @import "components/mui/Input/styles.css"; */
}
body {
  font-family: "font-DosisRegular", "font-InterRegular";
  scroll-behavior: smooth;
}
.green-background {
  background-color: #d3f1e8 !important;
}
.explore-room-bg {
  background-color: #e5dbd9;
}
.contact-background {
  background-color: #9ad9db;
  color: #82b2b8;
}
.brown-color {
  color: #75602c;
}
.brown-border {
  border-color: #836f6a;
}
.pre-header {
  background: #719569;
  color: #fff;
}
.green-text {
  color: #3b5831;
}
.bookroom {
  border: 1px solid #3b5831;
  color: #3b5831;
  background-color: #fff !important;
}
.bookroom:hover {
  background-color: #75602c !important;
  border: none;
  color: #fff;
}
.main-header {
  background-color: #ffffff;
}
.pastel-brown {
  background-color: #f7f4f2;
}
.border-red {
  border-color: #3b5831;
}
.btn:hover {
  color: #3b5831;
  border: "1px solid #3b5831";
  font-weight: bold;
}
.headerBlack {
  color: #020106;
}
.headerRed {
  /* color: #f4608d; */
  color: #3b5831;
}

.button-hover {
  background-color: #1c5169;
  color: #fff;
}
.font-color {
  /* color: #317fa4;
   */
  color: #5c6969;
}
.title-style-2 hr {
  width: 23px;
  height: 2px;
  background: #e4b248;
  margin: 0 auto;
  margin-top: 13px;
}
/* carousel */
.image-container,
.facility-image {
  height: 400px;
  position: relative;
  overflow: hidden;
}
.image-container img,
.facility-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* @media (min-width: 1024px) {
  .image-container,
  .facility-image {
    width: 50%;
    height: 400px;
  }
} */
@media (max-width: 640px) {
  .slider-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.slick-dots li button:before {
  font-size: 16px !important;
}
.slick-prev:before,
.slick-next:before {
  content: "" !important;
}
.slick-dots li.slick-active button:before {
  color: #3b5831 !important;
}

.image-container {
  position: relative;
  /* other styles for image container */
}

.caption {
  position: absolute;
  top: 10%;
  color: #fff;
  width: 100%;
  text-align: center;
}
.room-caption {
  position: relative;
}
.room-caption p {
  /* background-color: #eaeaea; */
  background-color: #f3f4f7;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  bottom: 0;
  left: 0;
}
.contactus {
  position: relative;
}
.contactus .icon {
  position: absolute;
  top: -20px;
  left: 50%;
}
.flip-card {
  perspective: 1000px;
  height: 100%;
  width: 100%;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flip-card-back {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: rotateY(180deg);
}
