@font-face {
  font-family: "DosisRegular";
  src: local("DosisRegular"), url("./fonts/Dosis-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "DosisBold";
  src: local("DosisBold"), url("./fonts/Dosis-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "DosisMedium";
  src: local("DosisMedium"), url("./fonts/Dosis-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "InterExtraBold";
  src: local("InterExtraBold"),
    url("./fonts/Inter-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "InterBold";
  src: local("InterBold"), url("./fonts/Inter-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"), url("./fonts/Inter-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"), url("./fonts/Inter-Medium.woff2") format("woff2");
}
